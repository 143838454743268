<template>
    <section class="section newsletter-signup-section" v-if="newsletterData">
        <div class="container">
            <div class="column-wrapper">
                <div class="column content">


                    <!-- <div class="text-wrapper" v-for="item in newsletterData" :key="item.id">
                        <h2 class="column-title" v-html="item.templateContentFields.displayTitle"></h2>
                    </div> -->

                    <p>Join our LEAD Community email list and receive practice inspiration, personal recommendations and
                        updates on our events.</p>
                </div>
                <div class="column form">
                    <!-- Begin Mailchimp Signup Form -->
                    <!-- <link
						href="//cdn-images.mailchimp.com/embedcode/classic-071822.css"
						rel="stylesheet"
						type="text/css"
					/> -->

                    <div id="mc_embed_signup">
                        <form
                            action="https://leadfieldhockey.us21.list-manage.com/subscribe/post?u=275e755c7cefd3e401ae41a86&id=0f0e4fc8e0&f_id=0090d8e6f0"
                            method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate"
                            target="_blank" novalidate>
                            <div class="inner-form-wrapper">
                                <div id="mc_embed_signup_scroll" class="form-fields-wrapper">
                                    <div class="mc-field-group">
                                        <label for="mce-EMAIL">Email Address
                                            <span class="asterisk">*</span>
                                        </label>
                                        <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL"
                                            placeholder="Enter your email here…" required />
                                        <span id="mce-EMAIL-HELPERTEXT" class="helper_text"></span>
                                    </div>
                                    <div id="mce-responses" class="clear">
                                        <div class="response" id="mce-error-response" style="display:none"></div>
                                        <div class="response" id="mce-success-response" style="display:none"></div>
                                    </div>
                                    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                                    <div style="position: absolute; left: -5000px;" aria-hidden="true">
                                        <input type="text" name="b_d3dc55d97d8a556b13953d9a0_f6fe0b78d0" tabindex="-1"
                                            value="" />
                                    </div>
                                </div>
                                <div class="submit-wrapper">
                                    <button type="submit" data-txt="Subscribe" name="subscribe" id="mc-embedded-subscribe"
                                        class="button">
                                        Subscribe
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <!--End mc_embed_signup-->

                    <!-- <NewsLetterSignupForm /> -->
                </div>
            </div>
        </div>
    </section>
</template>

<!-- <script
	type="text/javascript"
	src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"
></script> -->
<!-- <script type="text/javascript"></script> -->

<script>
/* eslint-disable */
//import NewsLetterSignupForm from "@/components/Forms/NewsLetterSignupForm";

export default {
    data() {
        return {};
    },
    mounted() {
        const mailChimpScript = document.createElement("script");
        mailChimpScript.setAttribute(
            "src",
            "https://s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"
        );
        document.head.appendChild(mailChimpScript);
    },
    //components: { NewsLetterSignupForm },
    inject: ["isAuthenticated"],
    props: ["newsletterData"],
};

// (function($) {
// 	window.fnames = new Array();
// 	window.ftypes = new Array();
// 	fnames[0] = "EMAIL";
// 	ftypes[0] = "email";
// 	fnames[1] = "FNAME";
// 	ftypes[1] = "text";
// 	fnames[2] = "LNAME";
// 	ftypes[2] = "text";
// 	fnames[3] = "ADDRESS";
// 	ftypes[3] = "address";
// 	fnames[4] = "PHONE";
// 	ftypes[4] = "phone";
// 	fnames[5] = "BIRTHDAY";
// 	ftypes[5] = "birthday";
// })(jQuery);
// var $mcj = jQuery.noConflict(true);
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>

<style lang="scss">
.section.newsletter-signup-section {
    .column.content * {
        @include font-size(18);
        color: #FFF;

        flex: 0 1 520px;
    }

    .top-level,
    label {
        @include remove-element();
    }

    form {
        position: relative;

        .inner-form-wrapper {
            display: flex;
            flex-flow: row wrap;

            .form-fields-wrapper {
                flex: 1;
            }

            .submit-wrapper {
                flex: 0 1 auto;
            }
        }

        .form-row,
        .mc-field-group {
            flex: 1;

            input[type="email"] {
                background: none;
                border: none;
                border-radius: 0;
                border-bottom: 1px solid #FFF;
                padding: 20px 150px 20px 0;
                color: #FFF;
                font-weight: $semiboldWeight;

                @include placeholder() {
                    color: #FFF;
                    font-weight: $regularWeight;
                }

                &:focus {
                    border-bottom-color: #FFF;
                }
            }
        }

        .submit-wrapper {
            width: auto;
            position: absolute;
            top: 0;
            right: 0;
            margin: 0;
        }
    }

    form .field-wrapper .input-wrapper:before {
        display: none;
    }

    .mc-field-group {
        position: relative;
    }

    #mc_embed_signup div.mce_inline_error {
        position: absolute;
        top: 100%;
        width: auto;
        margin-top: 10px;
        background: none;
        background: $errorColor;
        padding: 5px 10px;
        color: #fff;
        font-style: italic;
        @include font-size(12);
    }

    .mce-responses {
        position: relative;
    }

    #mce-success-response {
        position: absolute;
        top: 100%;
        width: auto;
        margin-top: 10px;
        background: none;
        background: $black;
        padding: 5px 10px;
        color: #fff;
        font-style: italic;
        @include font-size(12);
    }

    #mc_embed_signup input.mce_inline_error {
        border-color: $errorColor;
    }
}

@media only screen and (max-width: 600px) {
    .section.newsletter-signup-section {
        .column.content .column-title {
            @include font-size(18);
        }
    }

    .section.newsletter-signup-section form .submit {
        position: relative;
        margin-top: 15px;
    }
}
</style>
