<template>
    <footer>
        <NewsLetterSignupSection v-if="newsletterData && $route.name !== 'newsletter'"
            :newsletterData="newsletterData" />

        <section class="footer-section">
            <div class="container">
                <div class="column-wrapper">
                    <div class="column footer-intro">
                        <span class="footer-logo"><router-link to="/"><img class="logo" alt="Logo icon"
                                    src="@/assets/svgs/logo.svg" /></router-link></span>
                        <!-- <div v-if="footerData">
                            <div class="footer-text-wrapper" v-for="item in footerData" :key="item.id">
                                <div v-html="
                                    item.templateContentFields.contentFirst
                                "></div>
                            </div>
                        </div> -->
                        <!-- <div>
                            <div class="footer-text-wrapper">
                                <div>
                                    <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget
                                        dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes,
                                        nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium
                                        quis, sem. Nulla consequat massa quis enim.</p>
                                </div>
                            </div>
                        </div> -->
                    </div>
                    <div class="column navigation">
                        <h2 class="column-title">Explore</h2>
                        <nav>
                            <ul>
                                <li><router-link to="/masterclasses">Masterclasses</router-link></li>
                                <li v-if="isAuthenticated"><router-link to="/all-videos">All videos</router-link></li>
                                <li><router-link to="/coaches-trainers">Trainers & coaches</router-link></li>
                                <li><router-link to="/blogs">Blog</router-link></li>
                            </ul>
                        </nav>
                    </div>
                    <div class="column navigation">
                        <h2 class="column-title">More info</h2>
                        <nav>
                            <ul>
                                <li><router-link to="/faq">FAQ</router-link></li>
                                <li><router-link to="/who-we-are">Who we are</router-link></li>
                                <li><router-link to="/privacy-policy">Privacy policy</router-link></li>
                                <li><router-link to="/terms-and-conditions">Terms & Conditions</router-link></li>
                            </ul>
                        </nav>
                    </div>
                    <div class="column navigation">
                        <h2 class="column-title">Follow us</h2>
                        <nav>
                            <ul>
                                <li><a href="https://www.facebook.com/leadfieldhockey" target="_blank">Facebook</a></li>
                                <li><a href="https://www.instagram.com/leadfieldhockey/" target="_blank">Instagram</a>
                                </li>
                                <li><a href="https://www.linkedin.com/company/leadfieldhockey/"
                                        target="_blank">Linkedin</a>
                                </li>
                                <li><a href="https://www.tiktok.com/@leadfieldhockey" target="_blank">TikTok</a></li>
                                <li><a href="https://www.youtube.com/channel/UCZ0A0oX0zz61LdJhOvoNIuA"
                                        target="_blank">Youtube</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
        <div class="section copyright-bar">
            <div class="container">
                <span class="copy-txt">&copy; {{ new Date().getFullYear() }} LEAD FIELD HOCKEY</span>
            </div>
        </div>
    </footer>
</template>

<script>
import { fetchTemplateContentList } from "@streampac.io/chef_sp_1";

// @import component
import NewsLetterSignupSection from "@/components/NewsLetterSignupSection";
export default {
    data() {
        return {
            // newsletterData: null,
            newsletterData: true,
            footerData: null,
        };
    },
    //props: ["playlistId"],
    inject: ["isAuthenticated"],
    components: {
        NewsLetterSignupSection,
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            const fetchDataParams = {
                paging: {
                    pageNumber: 1,
                    pageSize: 20,
                },
            };

            //Content blok 2 | Newsletter
            fetchTemplateContentList(
                this.isAuthenticated,
                "077fbcde-d582-487c-9cd6-ca37728f8fcb",
                fetchDataParams
            ).then((response) => {
                if (response && response.length > 0) {
                    const sortedResponse = response.sort(
                        (a, b) => a.sortOrder - b.sortOrder
                    );

                    this.newsletterData = sortedResponse;
                }
            });

            //Content blok 3 | Footer
            fetchTemplateContentList(
                this.isAuthenticated,
                "db06f89b-6869-4365-bd78-23a015f2b839",
                fetchDataParams
            ).then((response) => {
                if (response && response.length > 0) {
                    const sortedResponse = response.sort(
                        (a, b) => a.sortOrder - b.sortOrder
                    );
                    this.footerData = sortedResponse;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>
