import { trackRouter } from "vue-gtag-next";
import { getData } from "@streampac.io/chef_sp_1";
import { createRouter, createWebHistory } from "vue-router";

import Home from "../views/Home";

const VideoOverview = () => import("../views/VideoOverview");
const VideoSingle = () => import("../views/VideoSingle");
const Account = () => import("../views/Account");
//const WatchList = () => import('../views/WatchList');

const SubscriptionPage = () => import("../views/SubscriptionPage");
const ActivateAccount = () => import("../views/ActivateAccount");

// const FormFields = () => import("../views/FormFields");

const RegisterPage = () => import("../views/RegisterPage");
const PaymentSucceeded = () => import("../views/PaymentSucceeded");
const PaymentFailed = () => import("../views/PaymentFailed");
const ResetPasswordPage = () => import("../views/ResetPasswordPage");

const Dashboard = () => import("../views/Dashboard");

//const Login = () => import('../views/Login');

const SearchResultsPage = () => import("../views/SearchResultsPage");

const NotFound = () => import("../views/NotFound");

// const PrivacyPolicy = () => import("../views/PrivacyPolicy");
// const TermsAndConditions = () => import("../views/TermsAndConditions");

const Faq = () => import("../views/Faq");

const TrainersAndCoaches = () => import("../views/TrainersAndCoaches");
const TrainersAndCoachesSingle = () => import("../views/TrainersAndCoachesSingle");

const Blog = () => import("../views/Blog");
const BlogSingle = () => import("../views/BlogSingle");

const Journey = () => import("../views/Journey");
const JourneySingle = () => import("../views/JourneySingle");

const Training = () => import("../views/Training");
const TrainingSingle = () => import("../views/TrainingSingle");

const ContentCategoryPage = () => import("../views/ContentCategoryPage");

const VideoCatTechnicalSkills = () => import("../views/VideoCatTechnicalSkills");
const VideoCatTacticalClasses = () => import("../views/VideoCatTacticalClasses");
const VideoCatTrainingExercises = () => import("../views/VideoCatTrainingExercises");
const VideoCatMasterClasses = () => import("../views/VideoCatMasterClasses");

const RegularPage = () => import("../views/RegularPage");
const WaitlistPage = () => import("../views/WaitlistPage");

const FavoritesPage = () => import("../views/FavoritesPage");


const NewsletterPage = () => import("../views/NewsletterPage");

const routes = [
  // {
  // 	path: '/login',
  // 	name: 'Login',
  // 	component: Login,
  // 	meta: { pageName: 'Login' }
  // },

  {
    path: "/",
    name: "Home",
    component: Home,
  },
{
    path: "/activate-account/:activateAccountId",
    name: "ActivateAccount",
    component: ActivateAccount,
    meta: { auth: false, pageName: "ActivateAccount" },
  },
  {
    path: "/accountinvitation/:activateAccountId",
    name: "ActivateAccount2",
    component: ActivateAccount,
    meta: { auth: false, pageName: "ActivateAccount" },
  },
//   {
//     path: "/formfields",
//     name: "formfields",
//     component: FormFields,
//   },
  {
    path: "/all-videos",
    name: "VideoOverview",
    component: VideoOverview,
    meta: { auth: true},
  },
  {
    path: "/video/:slug",
    name: "VideoDetails",
    component: VideoSingle,
  },
  {
    path: "/journeys",
    name: "JourneyOverview",
    component: Journey,
  },
  {
    path: "/journey/:slug",
    name: "JourneySingle",
    component: JourneySingle,
    props: true,
  },
  {
    path: "/my-journeys",
    name: "training",
    component: Training,
    meta: { auth: true}
  },
  {
    path: "/my-journey/:slug",
    name: "trainingSingle",
    component: TrainingSingle,
    meta: { auth: true}
  },
  {
    path: "/blogs",
    name: "blogsOverview",
    component: Blog,
  },
  {
    path: "/blog/:slug",
    name: "BlogSingle",
    component: BlogSingle,
    props: true,
  },
  {
    path: "/coaches-trainers",
    name: "trainersAndCoachesOverview",
    component: TrainersAndCoaches,
  },
  {
    path: "/coaches-trainers/:slug",
    name: "TrainerandCoachSingle",
    component: TrainersAndCoachesSingle,
    props: true,
  },
  {
    path: "/categories/:slug",
    name: "ContentCategoryPage",
    component: ContentCategoryPage,
    props: true,
    meta: { auth: true },
  },
  {
    path: "/videos/technical-skills",
    name: "VideoCatTechnicalSkills",
    component: VideoCatTechnicalSkills,
    props: true,
    meta: { auth: true },
  },
  {
    path: "/videos/tactical-classes",
    name: "VideoCatTacticalClasses",
    component: VideoCatTacticalClasses,
    props: true,
    meta: { auth: true },
  },
  {
    path: "/videos/training-exercises",
    name: "VideoCatTrainingExercises",
    component: VideoCatTrainingExercises,
    props: true,
    meta: { auth: true },
  },
  {
    path: "/videos/masterclasses",
    name: "VideoCatMasterClasses",
    component: VideoCatMasterClasses,
    props: true,
    meta: { auth: true },
  },
  {
    path: "/account",
    name: "Account",
    component: Account,
    meta: { auth: true, pageName: "Account" },
  },
  {
    path: "/sign-up",
    name: "RegisterPage",
    component: RegisterPage,
    meta: { pageName: "Sign up" },
  },
  {
    path: "/memberships",
    name: "Pricing",
    component: SubscriptionPage,
    meta: { pageName: "memberships" },
  },
  {
    path: "/payment-success",
    name: "PaymentSucceeded",
    component: PaymentSucceeded,
    meta: { pageName: "Payment success" },
  },
  {
    path: "/payment-failed",
    name: "PaymentFailed",
    component: PaymentFailed,
    meta: { pageName: "Payment failed" },
  },
  {
    path: "/forgot-password/:passwordForgotId",
    name: "ResetPasswordPage",
    component: ResetPasswordPage,
  },
  {
    path: "/forgotpassword/:passwordForgotId",
    name: "ResetPasswordPage2",
    component: ResetPasswordPage,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { auth: true, admin: true, pageName: "Dashboard" },
  },
  {
    path: "/search/:searchParam",
    name: "Search",
    component: SearchResultsPage,
    meta: {
      pageName: "Search",
      breadcrumbs: [
        {
          name: "Search",
          slug: "/search",
        },
      ],
    },
  },
  {
    path: "/my-favourites",
    name: "my-favourites-page",
    component: FavoritesPage,
  },
  // Regular pages -> all using the same template
  {
    path: "/technical-skills",
    name: "technical-skills-page",
    component: RegularPage,
    meta: { slug: 'technical-skills' },
  },
  {
    path: "/training-exercises",
    name: "training-exercises-page",
    component: RegularPage,
    meta: { slug: 'training-exercises' },
  },
  {
    path: "/tactical-classes",
    name: "tactical-classes-page",
    component: RegularPage,
    meta: { slug: 'tactical-classes' },
  },
  {
    path: "/masterclasses",
    name: "masterclasses-page",
    component: RegularPage,
    meta: { slug: 'masterclasses' },
  },
  {
    path: "/terms-and-conditions",
    name: "terms-and-conditions-page",
    component: RegularPage,
    meta: { slug: 'terms-and-conditions' },
  },
  {
    path: "/who-we-are",
    name: "who-we-are-page",
    component: RegularPage,
    meta: { slug: 'who-we-are' },
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy-page",
    component: RegularPage,
    meta: { slug: 'privacy-policy' },
  },
  {
    path: "/faq",
    name: "faq-page",
    component: Faq,
    meta: { slug: 'faq' },
  },
  {
    path: "/contact",
    name: "contact-page",
    component: RegularPage,
    meta: { slug: 'contact' },
  },
  {
    path: "/waitlist",
    name: "waitlist",
    component: WaitlistPage,
    meta: { slug: 'waitlist' },
  },
  {
    path: "/newsletter",
    name: "newsletter",
    component: NewsletterPage,
    meta: { slug: 'newsletter' },
  },
  // Pages end
  {
    path: "/:notFound(.*)",
    component: NotFound,
    pageName: "404 niet gevonden",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, _2, savedPosition) {
    if (savedPosition) {
      //return savedPosition;
      return { left: 0, top: 0 };
    } else if (to.hash) {
      return {
        selector: to.hash,
      };
    } else {
      return { left: 0, top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((route) => route.meta.auth)) {
    if (getData("loginData") === null) {
      console.log("no access-token");
      next({
        path: "/",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

trackRouter(router, { useScreenview: true });

export default router;
