<template>
    <div class="search-wrapper" :class="{ activebar: toggleBar, issearchpage: ifIsSearch() }">
        <button class="search-btn" @click="toggleSearchBar"></button>
        <form class="search-form" @submit.prevent="goToSearch" autocomplete="off">
            <input type="text" v-model="userInput" class="search-bar" placeholder="Search" ref="searchBar" />
            <button @click="toggleSearchBar" class="close-search" type="button" v-if="!ifIsSearch()"></button>
            <button class="extra-btn" type="button" @click="goToSearch()">

            </button>
        </form>
        <ul class="results-wrapper" v-if="(userInput && toggleBar) || (ifIsSearch && userInput)">
            <li class="item" v-for="item in changedList" :key="item">
                <router-link @click="removeList" :to="'/video/' + item.slug">{{ item.title }}</router-link>
            </li>
            <li class="item show-search-results" v-if="userInput && changedList.length > 0">
                <button class="button show-all-search-btn" @click="goToSearch()">
                    Show all results
                </button>
            </li>
            <li class="item error" v-if="userInput && changedList.length <= 0">
                <p>No results found..</p>
            </li>
        </ul>
    </div>
</template>

<script>
import { fetchVideoContentList } from "@streampac.io/chef_sp_1";

// import LoginPopup from '@/components/LoginPopup';

export default {
    data() {
        return {
            userInput: "",
            data: null,
            changedList: [],
            toggleBar: false,
        };
    },
    inject: ["isAuthenticated"],
    props: {
        activeSearchState: {
            type: Boolean,
            required: false,
        },
    },
    created() {
        this.fetchFullList();
    },

    watch: {
        $route() {
            this.userInput = "";
            this.toggleBar = false;
        },
        userInput() {
            this.fetchFullList();
        },
    },
    methods: {
        ifIsSearch() {
            if (
                this.$route.name &&
                this.$route.name.toLowerCase() == "search"
            ) {
                return true;
            }

            if (this.activeSearchState && this.activeSearchState == "true") {
                return true;
            }

            return false;
        },
        filteredList() {
            if (this.data && this.userInput) {
                // const filteredList = this.data.filter(
                // 	(item) =>
                // 		item.title.toLowerCase().includes(this.userInput.toLowerCase()) ||
                // 		item.category.toLowerCase().includes(this.userInput.toLowerCase())
                // );

                //Only show 5 results
                this.changedList = this.data.slice(0, 5);
            }
        },
        fetchFullList() {
            const inputData = JSON.stringify({
                paging: {
                    pageNumber: this.getPageNumber,
                    pageSize: this.getPageSize,
                },
                filter: {
                    searchTerm: this.userInput,
                },
                flattenFields: true,
            });

            this.ApiQueryParams = inputData;

            fetchVideoContentList(this.isAuthenticated, inputData).then(
                (response) => {
                    //const publishedPosts = response.filter((item) => item.published);

                    this.data = response;

                    setTimeout(() => {
                        this.filteredList();
                    }, 100);
                }
            );
        },
        toggleSearchBar() {
            this.toggleBar = !this.toggleBar;
            this.userInput = "";

            //Focus input after click
            this.$refs.searchBar.focus();
        },
        goToSearch() {
            if (this.userInput != "") {
                this.$router.push(`/search/${this.userInput}`);

                this.$refs.searchBar.blur();
            }
        },
        removeList() {
            //('close item');
        },
    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>
