<template>
    <div v-if="!this.$store.state.underConstructionActive ||
        (typeof getCookie('under-construction-validated') !==
            undefined &&
            getCookie('under-construction-validated') == 'yes')
        " id="global-wrapper" class="global-wrapper" :class="[
        currentRouteName,
        { 'not-logged-in': !isAuthenticated },
        { 'logged-in': isAuthenticated },
    ]">
        <metainfo></metainfo>
        <Header />
        <main>
            <router-view :key="$route.fullPath" />
        </main>
        <Footer />
    </div>
    <div class="global-under-construction-wrapper" v-else>
        <UnderConstruction />
    </div>
</template>

<script>
import { computed } from "vue";
import { getData } from "@streampac.io/chef_sp_1";
// @import components
import Header from "@/components/Header";
import Footer from "@/components/Footer";

import { fetchEnumFlatValues, getUserProfile, getUserToken } from "@streampac.io/chef_sp_1";

import { fetchUserFavoriteVideos } from '@streampac.io/chef_sp_1';

import themeFunctions from "./functions.js";

import { defineAsyncComponent } from "vue";

const UnderConstruction = defineAsyncComponent(() =>
    import("@/views/UnderConstruction")
);

export default {
    metaInfo() {
        return {
            title: "Lead hockey",
            // titleTemplate: '%s | LEAD Hockey online'
        };
    },
    data() {
        return {
            isAuthenticated: false,
            savedToken: "",
            getSubscriptions: [],
            getPayPerView: [],
            getMediaIds: [],
            showMobileVersion: false,
        };
    },
    provide: function () {
        return {
            isAuthenticated: computed(() => this.isAuthenticated),
            setAuthenticated: this.setAuthenticated,
            //isMobileVersion: computed(() => this.isMobileScreen())
        };
    },
    created() {
        this.isMobileScreen();
        this.setAuthenticated(getData("loginData") ? true : false);
        this.getOrderData();
        this.saveEnumLookup();
    },
    watch: {
        $route() {
            window.scrollTo(0, 0);
        },
        isAuthenticated(val) {
            //Check to see if the user is logged in. If yes save favorites
            if (val && val === true) {
                this.saveUserProfile();
            }
        },
        '$store.state.getUserProfile'() {
            this.saveUserFavorites();
        }
    },
    components: {
        Header,
        Footer,
        UnderConstruction,
    },
    computed: {
        currentRouteName() {
            if (this.$route.name) {
                return this.$route.name.toLowerCase();
            } else {
                return false;
            }
        },
    },
    methods: {
        setAuthenticated: function (value) {
            this.isAuthenticated = value;
        },
        isMobileScreen() {
            // const test = () => {
            // }
            // window.addEventListener('resize', () => {
            // 	const windowSize = window.innerWidth;
            // 	if (windowSize <= 1440) {
            // 		this.showMobileVersion = true;
            // 	}
            // 	this.showMobileVersion = false;
            // });
            // const windowSize = window.innerWidth;
            // if (windowSize <= 1440) {
            // 	this.showMobileVersion = true;
            // }
            // return this.showMobileVersion;
        },
        checkLoggedInPages() {
            if (
                this.$route.name !== "Login" &&
                this.$route.name !== "ActivateAccount"
            ) {
                return true;
            }

            return false;
        },
        getOrderData() {
            setTimeout(() => {
                themeFunctions.saveOrderDataInStore(this, this.isAuthenticated);
            }, 200);
        },
        getCookie(cname) {
            let name = cname + "=";
            let ca = document.cookie.split(";");
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == " ") {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        },
        saveEnumLookup() {
            fetchEnumFlatValues(this.isAuthenticated).then((response) => {
                this.$store.commit("saveEnumLookup", response);
            });
        },
        saveUserProfile() {
            if (this.isAuthenticated) {
                getUserToken(token => {
                    getUserProfile(token).then(response => {
                        this.$store.commit("saveUserProfile", response);
                    });
                });
            }
        },
        saveUserFavorites() {
            setTimeout(() => {
                const getUserProfile = this.$store.state.getUserProfile;
                if (this.isAuthenticated && getUserProfile) {

                    const params = {
                        "userId": getUserProfile.userId,
                        "flattenFields": false,
                        "getUnpublishVideosToo": false
                    };

                    fetchUserFavoriteVideos(this.isAuthenticated, params).then(response => {
                        if (response) {
                            this.$store.commit("saveUserFavoriteVideos", response);
                        }
                    });
                }
            }, 300);
        },
    },
};
</script>

<style lang="scss">
// @import styles
@import "./assets/css/global.scss";
@import "./assets/css/responsive-global.scss";
</style>
