<template>
    <HeroPage v-if="pageData" :data="pageData" heroType="center" />
    <!-- <PageLayoutBuilder v-if="pageData" :data="pageData" /> -->

    <PageLayoutBuilder v-if="pageData" :data="pageData">
        <template #section-intro-section="section">
            <section v-if="section.data" class="section intro-section" data-template-name="intro-section">
                <span class=" anchor" id="intro-section"></span>
                <div class="container">
                    <div class="section-title-wrapper"
                        v-if="section.data && section.data.title && section.data.title !== '' || section.data && section.data.subtitle && section.data.subtitle !== ''">
                        <h2 class="section-title" v-if="section.data && section.data.title && section.data.title !== ''">{{
                            section.data.title }}</h2>
                        <span class="section-subtitle"
                            v-if="section.data && section.data.subtitle && section.data.subtitle !== ''">{{
                                section.data.title }}</span>
                    </div>
                    <div class="column-wrapper" v-if="section.data.elements && section.data.elements.length > 0">
                        <LinkBlockColumn v-for="( element, elementIndex ) in section.data.elements" :key="elementIndex"
                            :data="element" />
                    </div>
                </div>
            </section>
        </template>

        <template #header-section-home-pricing-cta="section">
            <div class="section-top">
                <img alt="LEAD Hockey - logo" src="@/assets/svgs/logo-icon.svg" class="cta-logo" />
                <div class="section-title-wrapper"
                    v-if="section.data && section.data.title && section.data.title !== '' || section.data && section.data.subtitle && section.data.subtitle !== ''">
                    <h2 class="section-title" v-if="section.data && section.data.title && section.data.title !== ''">{{
                        section.data.title }}</h2>
                    <span class="section-subtitle"
                        v-if="section.data && section.data.subtitle && section.data.subtitle !== ''">{{
                            section.data.title }}</span>
                </div>
            </div>
        </template>
        <template #footer-section-home-pricing-cta>
            <div class="section-bottom">
                For further details please read our <router-link to="/terms-and-conditions">Terms &
                    Conditions</router-link>.
            </div>
        </template>
    </PageLayoutBuilder>

    <!-- <PlayList playlistId="448266d0-46a1-46c7-bd22-710ecc4a7990" /> -->
</template>

<script>
import {
    fetchPageBySlug,
} from "@streampac.io/chef_sp_1";

import { defineAsyncComponent } from "vue";

import HeroPage from "@/components/HeroPage";

const PageLayoutBuilder = defineAsyncComponent(() => import("@/components/PageLayoutBuilder"));
const LinkBlockColumn = defineAsyncComponent(() => import("@/components/LinkBlockColumn"));
// const PlayList = defineAsyncComponent(() => import("@/components/PlayList"));

export default {
    name: "home",
    inject: ["isAuthenticated"],
    metaInfo() {
        return {
            title:
                "Home | LEAD Hockey",
            description:
                "",
        };
    },
    data() {
        return {
            pageData: null,
            fetchDataParams: {
                paging: {
                    pageNumber: 1,
                    pageSize: 6,
                },
            },
        };
    },
    components: {
        HeroPage,
        PageLayoutBuilder,
        LinkBlockColumn,
        // PlayList,
    },
    watch: {
        isAuthenticated() {
            this.pageData = null;
            setTimeout(() => {
                this.fetchPage();
            }, 50);
        },
    },
    created() {
        this.fetchPage();
    },
    methods: {
        fetchPage() {
            if (this.isAuthenticated) {
                //tmp-pagelayout
                fetchPageBySlug(this.isAuthenticated, "home-logged-in").then(
                    (response) => {
                        this.pageData = response;
                    }
                );
            } else {
                fetchPageBySlug(this.isAuthenticated, "home-not-logged-in").then(
                    //fetchPageBySlug(this.isAuthenticated, "tmp-pagelayout").then(
                    (response) => {
                        this.pageData = response;
                    }
                );
            }
        },
    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>
