<template>
    <section class="hero page" v-if="data"
        :class="[{ 'no-bg': checkIfBgOrVid() }, 'type-' + heroType, { 'type-center': !headerImage }]"
        :data-hero-size="heroSize">
        <div class="bg-vid-wrapper">
            <div class="bg-vid-inner-container" v-if="shortAssetData">
                <div class="short-vid-wrapper">
                    <div :id="'hero-' + shortAssetData.mediaId" style="position: absolute; width: 100%; height: 100%"></div>
                </div>
            </div>
            <transition name="vid-fade">
                <div class="thumbnail" v-if="!videoStarted && headerImage">
                    <div class="bg-wrapper" v-if="headerImage.url" v-bind:style="{
                        'background-image': 'url(' + headerImage.url + ')',
                    }"></div>
                </div>
            </transition>
        </div>
        <div class="container">
            <div class="column-wrapper">
                <div class="column content">

                    <h1 class="title" v-html="data.headerTitle"></h1>
                    <span class="subtitle" v-if="data.headerSubtitle" v-html="data.headerSubtitle"></span>
                    <div class="intro" v-if="data.headerIntroText &&
                        data.headerIntroText !== '<p><br></p>'
                        " v-html="data.headerIntroText"></div>
                    <ul class="button-wrapper" v-if="buttonData && buttonData.length > 0">
                        <li v-for="buttonItem in buttonData" :key="buttonItem.slug">
                            <router-link class="button" :data-txt="buttonItem.label" :to="buttonItem.slug">{{
                                buttonItem.label }}</router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {
    fetchCmsMedia,
    fetchVideoContentDetails,
} from "@streampac.io/chef_sp_1";

export default {
    data() {
        return {
            shortAssetData: null,
            headerImage: null,
            videoStarted: false,
            buttonData: [],
            videoShortUrl: null,
        };
    },
    inject: ["isAuthenticated"],
    props: ["data", "heroType", "heroSize", "vixyFlavorId"],
    components: {},
    created() {
        if (this.data) {
            if (this.data && this.data.headerImage && this.data.headerImage !== "") {
                this.resolveCmsMedia(this.data.headerImage, "headerImage");
            }

            if (
                this.data &&
                this.data.headerHighlightVideo !== "" &&
                this.data.headerHighlightVideo !== undefined
            ) {
                fetchVideoContentDetails(
                    this.isAuthenticated,
                    this.data.headerHighlightVideo,
                    "?flattenFields=true"
                ).then((response) => {
                    this.shortAssetData = response;

                    setTimeout(() => {
                        this.initPlayer();
                    }, 300);
                });
            }

            let buttonArray = [];

            if (this.data.headerButtonUrl && this.data.headerButtonUrl !== "") {
                let buttonLabel = "Read more";

                if (
                    this.data.headerButtonLabel &&
                    this.data.headerButtonLabel !== ""
                ) {
                    buttonLabel = this.data.headerButtonLabel;
                }

                const buttonItem = {
                    slug: "/" + this.data.headerButtonUrl,
                    label: buttonLabel,
                };

                buttonArray.push(buttonItem);
            }

            if (
                this.data.headerSecondButtonUrl &&
                this.data.headerSecondButtonUrl !== ""
            ) {
                let buttonLabel = "Read more";

                if (
                    this.data.headerSecondButtonLabel &&
                    this.data.headerSecondButtonLabel !== ""
                ) {
                    buttonLabel = this.data.headerSecondButtonLabel;
                }

                const buttonItem = {
                    slug: "/" + this.data.headerSecondButtonUrl,
                    label: buttonLabel,
                };

                buttonArray.push(buttonItem);
            }

            this.buttonData = buttonArray;
        }
    },
    methods: {
        resolveCmsMedia(val, name) {
            if (val !== "") {
                fetchCmsMedia(this.isAuthenticated, val).then((response) => {
                    this[name] = response;
                });
            }
        },
        stripHtml(val) {
            const strippedString = val.replace(/(<([^>]+)>)/gi, "");

            return strippedString;
        },
        initPlayer() {
            if (this.shortAssetData && this.shortAssetData.kalturaSession && this.shortAssetData.kalturaSession !== '') {

                const { kWidget } = window;

                kWidget.embed({
                    targetId: "hero-" + this.shortAssetData.mediaId,
                    wid: "_" + this.$store.state.getVixyParams.partner,
                    uiconf_id: this.$store.state.getVixyParams.uiconfid,
                    flashvars: {
                        ks: this.shortAssetData.kalturaSession,
                        loop: true,
                        playlistAPI: {
                            autoPlay: true,
                        },
                        //Remove controls
                        "controlBarContainer.plugin": false,
                        "largePlayBtn.plugin": false,
                        "loadingSpinner.plugin": false,
                    },
                    entry_id: this.shortAssetData.mediaId,
                    readyCallback: () => {
                        this.videoStarted = true;
                    },
                });
            }
        },
        formatDate() {
            let initialDate = this.data.publicationDate;

            const months = [
                "Jan.",
                "Feb.",
                "Mrt.",
                "Apr.",
                "Mei.",
                "Jun.",
                "Jul.",
                "Aug.",
                "Sept.",
                "Okt.",
                "Nov.",
                "Dec.",
            ];

            let d = new Date(initialDate);

            const monthIndex = d.getMonth();
            const monthName = months[monthIndex];
            const year = d.getFullYear();
            const day = d.getDate();

            return day + " " + monthName + " " + year;
        },
        checkIfBgOrVid() {
            if (this.shortAssetData && this.shortAssetData.mediaId && this.shortAssetData.mediaId !== '') {
                return false;
            }

            if (this.headerImage && this.headerImage !== '') {
                return false;
            }

            return true;
        }
    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>

