import "regenerator-runtime";
import { createMetaManager } from "vue-meta";
import { plugin as vueMetaPlugin } from "vue-meta";

import { createApp } from "vue";
import { createStore } from "vuex";
import App from "./App.vue";
//import './registerServiceWorker'
import router from "./router";
import VueGtag from 'vue-gtag-next';

const store = createStore({
    state() {
        return {
            displayLoginPopup: false,
            displayPasswordPopup: false,
            storeIsAuthenticated: false,
            underConstructionActive: false,
            displayChangeUsernamePopup: false,
            getUserData: null,
            getUserLevel: "lead",
            getUserSubscriptions: [],
            getRentedMedia: [],
            getRentedMediaIDs: [],
            getUserProfile: null,
            getEnumLookup: null,
            getHeaderType: "",
            getContentTypes: {
                trainersAndCoaches: "5ac1c5ea-4224-4ead-a937-69f5bc417573",
                faq: "5e4595a4-fd0d-4f0d-856f-40d926862b34",
                masterClasses: "e9049c7d-1989-4f3e-8ab9-2d664f19805b",
                videoCategories: "f7d1ade9-f551-4851-ac5f-f566f73220e9",
                journey: "87d05cb3-4339-416d-9f13-b43e0a84de0c",
                blog: "2bf7b12a-4d2f-4ea4-81fa-af6c2a23b5d8",
            },
            getVixyParams: {
                partner: 635,
                uiconfid: 23459563,
            },
            getUserFavoriteVideos: [],
            getTrainingStageElements: [],
            submitTrainingStageElementsToTraining: false,
        };
    },
    mutations: {
        toggleLoginPopup(state) {
            state.displayLoginPopup = !state.displayLoginPopup;
        },
        showLoginPopup(state) {
            state.displayLoginPopup = true;
        },
        hideLoginPopup(state) {
            state.displayLoginPopup = false;
        },
        togglePasswordPopup(state) {
            state.displayPasswordPopup = !state.displayPasswordPopup;
        },
        showPasswordPopup(state) {
            state.displayPasswordPopup = true;
        },
        hidePasswordPopup(state) {
            state.displayPasswordPopup = false;
        },
        storeSetAuthenticated(state) {
            state.storeIsAuthenticated = true;
        },
        storeRemoveAuthenticatedStatus(state) {
            state.storeIsAuthenticated = false;
        },
        removeUnderConstruction(state) {
            state.underConstructionActive = false;
        },
        hideChangeUsernamePopup(state) {
            state.displayChangeUsernamePopup = false;
        },
        showChangeUsernamePopup(state) {
            state.displayChangeUsernamePopup = true;
        },
        setUserData(state, data) {
            state.getUserData = data;
        },
        deleteUserData(state) {
            state.getUserData = null;
        },
        saveUserSubscriptions(state, data) {
            state.getUserSubscriptions = data;
        },
        saveRentedMedia(state, data) {
            state.getRentedMedia = data;
        },
        saveRentedMediaIDs(state, data) {
            state.getRentedMediaIDs = data;
        },
        deleteUserSavedData(state) {
            state.getUserSubscriptions = [];
            state.getRentedMedia = [];
            state.getRentedMediaIDs = [];
        },
        saveEnumLookup(state, data) {
            state.getEnumLookup = data;
        },
        setHeaderType(state, data) {
            state.getHeaderType = data;
        },
        saveUserProfile(state, data) {
			state.getUserProfile = data;
		},
        saveUserFavoriteVideos(state, data) {
			state.getUserFavoriteVideos = data;
		},
        triggerFavoriteFetch( state, data ){
            state.triggerFavoriteFetch = data;
        },
        //New
        setTrainingStageElements( state, data ){
             state.getTrainingStageElements = data;
        },
        triggerSubmitTrainingStageElementsToTraining( state, data ){
            state.submitTrainingStageElementsToTraining = data;
        },
    },
});

const app = createApp(App);
app.config.devtools = true;

//TMP
app.config.unwrapInjectedRef = true;

app.use(VueGtag, {
	property: {
		id: 'G-8ESE4MX4BG',
		anonymizeIp: true
	}
});

app.use(store);
app.use(router);
// app.use(VueMeta);
app.use(vueMetaPlugin);
app.use(createMetaManager());

app.mount("#app");
