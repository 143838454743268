import { getOrders } from '@streampac.io/chef_sp_1';

export default {
	saveOrderDataInStore(that, isAuthenticated) {
		// setTimeout(() => {
		if (isAuthenticated) {
			getOrders(isAuthenticated).then(response => {
				//Return unique items
				const unique = (value, index, self) => {
					return self.indexOf(value) === index;
				};

				const returnSubscriptionsObj = response.filter(
					item =>
						item.pricePlan != null &&
						item.status == 'succeeded' &&
						Date.parse(item.payedUntill) > Date.now()
				);

				const payPerViewObj = response.filter(
					item =>
						item.pricePlan == null &&
						item.status == 'succeeded' &&
						Date.parse(item.payedUntill) > Date.now()
				);

				let getSubscriptions = returnSubscriptionsObj;
				let getPayPerView = payPerViewObj;

				let tempMediaArray = [];

				//Filter for unique items
				that.getPayPerView.forEach(element =>
					tempMediaArray.push(element.mediaId)
				);

				let getMediaIds = tempMediaArray.filter(unique);

				that.$store.commit('saveUserSubscriptions', getSubscriptions);
				that.$store.commit('saveRentedMedia', getPayPerView);
				that.$store.commit('saveRentedMediaIDs', getMediaIds);
			});
		}
		// }, 100);
	},
    getEnumVal(val) {
        const enums = this.$store.state.getEnumLookup;

        if (enums && enums.length > 0) {
            const enumFilter = enums.filter((item) => item.id === val);

            if (enumFilter && enumFilter.length > 0) {
                return enumFilter[0].enumValue;
            }
        }

        return "";
    },

	// fetchMediaList(this.isAuthenticated, 'default').then(response => {
	// 	const mediaItems = response.filter(item =>
	// 		this.getMediaIds.includes(item.mediaId)
	// 	);
	// 	this.mediaItemObj = mediaItems;
	// });
};